import React, { useContext, useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'
import Modal from '../Modal';
import ModeSwitch from '../ModeSwitch';
import Section from '../Section';
import DropDown from './DropDown';
import { Grid, Stack, Button, Typography, Box } from "@mui/material";
// import { useAccount, useNetwork } from "wagmi";

import './header.scss'
// import { walletContext } from '../../contexts/walletContext'

// import { ConnectButton } from '@rainbow-me/rainbowkit';

export default function Web3Header() {
  const [mobileToggle, setMobileToggle] = useState(false)
  const [resourceToggle, setResourceToggle] = useState(false)
  const [isPaid, setPaid] = useState(false);

  // const { address } = useAccount();
  // const { chain } = useNetwork();

  // useEffect(() => {
  //   setMobileToggle(mobileToggle);
  // }, [mobileToggle])


  // const { account } = useContext(walletContext)
  //   <li className="menu-item-has-children"><ScrollLink to="hero" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Home</ScrollLink>
  //   <DropDown>
  //     <ul>
  //       <li><Link to="/">Default Home</Link></li>
  //     </ul>
  //   </DropDown>
  // </li>

  return (
    <>
      <Section tag='header' className="cs-site_header cs-style1 cs-primary_color">
        <div className='cs-white'>
          {/* <marquee width="100%" direction="left" behavior="scroll" scrollamount="4">
            <span className="cs-red">WARNING: </span>We are aware of scam projects attempting to impersonate our project. These scams may try to mimic our name, logo, or website design to mislead investors. Please be diligent and ensure you are interacting with the official catchcoin Website. We will never ask you to send us your cryptocurrency directly. Only invest through our official channels listed on our website. If you see a project claiming to be associated with us but it is not listed on our website, it is a scam. Please report any suspicious activity to our team.

          </marquee> */}
        </div>

        <Section className="cs-main_header">
          <Section className="container-fluid">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to='/' className="cs-site_branding cs-accent_color">
                  <img src="/images/logo.png" alt="Logo" className="cs-hide_dark" width={"64px"} />
                  <img src="/images/logo.png" alt="Logo" className="cs-hide_white" width={"64px"} />
                  <img src="/images/catchcoin.svg" alt="Catchcoin Logo" class="cs-catchcoin-text" />
                </Link>

              </Section>
              <Section className="cs-main_header_right">
                <Section className="cs-nav">
                  <ul className="cs-nav_list" style={{ display: `${mobileToggle ? 'block' : 'none'}` }}>
                    <li><Link spy={true} smooth={true} offset={-80} duration={500} to='/'>Home</Link></li>
                    <li><ScrollLink to="about" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>About</ScrollLink></li>
                    <li><ScrollLink to="roadmap" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Roadmap</ScrollLink></li>
                    <li><ScrollLink to="tokenomics" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Tokenomics</ScrollLink></li>
                    <li><ScrollLink to="team" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>Team</ScrollLink></li>
                    <li><ScrollLink to="faq" spy={true} smooth={true} offset={-80} duration={500} onClick={() => setMobileToggle(false)}>FAQ</ScrollLink></li>
                    <li className="dropdownMenu">
                      {
                        mobileToggle ?
                          <span className="resources-nav" onClick={() => setResourceToggle(!resourceToggle)}>Resources</span>
                          :
                          <span className="resources-nav">Resources</span>
                      }
                      <ul className={mobileToggle && resourceToggle ? "dropdownContent dropdownResourcesMobile" : "dropdownContent"}>
                        <li><Link spy={true} smooth={true} offset={-80} duration={500} to='pdf/Whitepaper.pdf' target="_blank">Whitepaper</Link></li>
                        <li><Link spy={true} smooth={true} offset={-80} duration={500} to='pdf/Pitchdeck.pdf' target="_blank">Pitchdeck</Link></li>
                        <li><Link spy={true} smooth={true} offset={-80} duration={500} to='https://github.com/cyberscope-io/audits/blob/main/catchcoin/audit_base.pdf' target="_blank">Audit</Link></li>
                        <li><Link spy={true} smooth={true} offset={-80} duration={500} to='https://pinksale.notion.site/catchcoin-KYC-Verification-113a1dc597c94fb887632889539b89ed' target="_blank">KYC</Link></li>
                      </ul>
                    </li>
                  </ul>
                  <span className={mobileToggle ? "cs-munu_toggle cs-toggle_active" : "cs-munu_toggle"} onClick={() => setMobileToggle(!mobileToggle)}><span></span></span>
                </Section>
                <Section className="cs-toolbox">

                  {/* <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openAccountModal,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted,
                    }) => {
                      // Note: If your app doesn't use authentication, you
                      // can remove all 'authenticationStatus' checks
                      const ready = mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus || authenticationStatus === "authenticated");

                      return (
                        <div
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none",
                              paddingTop: "10px",
                            },
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={openConnectModal}
                                  type="button"
                                  sx={{
                                    fontWeight: 'bold',
                                    borderColor: 'white',
                                  }}
                                >
                                  Connect Wallet
                                </Button>
                              );
                            }
                            // console.log(chain.id);
                            if (chain.unsupported && chain.id !== 56 && chain.id !== 97) {
                              return (
                                <Button
                                  onClick={openChainModal}
                                  variant={"contained"}
                                  color={"error"}
                                >
                                  Wrong network
                                </Button>
                              );
                            }

                            return (
                              <Stack spacing={1}>
                                <ConnectButton
                                  accountStatus={{
                                    smallScreen: 'address',
                                    largeScreen: 'address',
                                  }}
                                  chainStatus={{
                                    smallScreen: "icon",
                                    largeScreen: "icon",
                                  }}
                                  showBalance={{
                                    smallScreen: false,
                                    largeScreen: true,
                                  }}
                                />


                              </Stack>
                            );
                          })()}
                        </div>
                      );
                    }}
                  </ConnectButton.Custom> */}

                  <ModeSwitch />

                  {/* {account === ''? 
                  
                  <ConnectButton /> :
                  <Section tag='span' className="cs-btn cs-btn_filed cs-accent_btn">
                    <Icon icon="ion:wallet-outline" />
                    <Section tag='span'>{account.substring(0, 10)}</Section>
                  </Section>} */}
                </Section>

              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  )
}
